import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AboutHeading = () => {
  return (
    <div className="aboutTitle relative sm:h-40 h-80">
      <StaticImage
        src="../../../assets/images/about/main.png"
        formats={["auto", "webp", "avif", "png"]}
        className="h-full"
        alt="Site Logo"
      />
      <h1
        className="text-mainHeading sm:text-mainHeadingMobile absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
        text-center font-OswaldMedium text-heading-color w-1/2 sm:w-4/5">
        We are Smiith & Mould and we bring artistry in metal.
      </h1>
      <div className="w-32 absolute -bottom-12 right-12 esm:-bottom-8 sm:right-2 esm:w-20 sm:w-24 xl:w-48 xl:-bottom-16 ">
        <StaticImage
          src="../../../assets/images/home/featureCircle.svg"
          quality={95}
          className="w-full border-0"
          formats={["auto", "webp", "avif"]}
          objectFit={"contain"}
          alt="Feature Circle Middle"
        />
      </div>
    </div>
  );
};

export default AboutHeading;
