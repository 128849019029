import * as React from "react";
import About from "../components/About";
import Footer from "../components/Footer";
import Seo from "../components/seo";
import Navbar from "../components/Navbar";

const NotFoundPage = () => (
  <>
    <Seo title="Smiith and Mould: About Us" />
    <Navbar />
    <About />
    <Footer />
  </>
);

export default NotFoundPage;
