import React from "react";

const Mission = () => {
  return (
    <div className="text-custom-teal-body md:text-center md:m-0 md:p-5 pt-8 md:w-full w-1/2 ml-32 md:font-LatoRegular font-MyriadProRegular">
      <div>
        <h1 className="herotitleOutline text-custom-teal-head md:text-3xl">
          Our Mission
        </h1>
        <p className="mb-2 italic font-bold md:font-normal md:not-italic ">
          Achieving Operational Excellence
        </p>
        <p className="mb-2 md:text-base text-sm">
          We explore and defne identity from our traditions, pioneering competence
          and diligence to deliver the best and most secure products to you. We
          believe in bringing a social change by introducing environment-friendly and
          sustainable products.
        </p>
        <p className="md:text-base text-sm">
          Our features comprise a wealthy array of applications that evoke customer
          proximity, oriented service, solution-driven design concepts and
          hassle-free installation.{" "}
        </p>
      </div>
      <div className="md:pt-8 pt-4">
        <h1 className="herotitleOutline md:text-3xl text-custom-teal-head">
          Our Promise
        </h1>
        <p className="mb-2 italic font-bold md:font-normal md:not-italic ">
          Simpliﬁed Problem Solving
        </p>
        <p className="md:text-base text-sm">
          Our company constantly evolves to the booming technology, maintaining the
          market power and consistently developing fruitful associations and
          trustworthy relationships with people. S&M etches strong beliefs on the
          road to long term investments and emerging future prospects with zeal and
          decorum
        </p>
      </div>
    </div>
  );
};

export default Mission;
