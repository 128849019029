import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";

const FriendHover = ({ className, name, role, projectName }) => (
  <button
    className={`bg-[#deb35d] ${className} absolute inset-0 pl-5 pr-5 pt-2 text-custom-teal text-left w-full
    opacity-0 hover:opacity-100 focus:opacity-100 duration-300`}>
    <span className="font-LatoBold italic text-xl block esm:text-base m-0">
      {name}
    </span>
    <span className="font-LatoBold italic text-xl block esm:text-base m-0">
      {role}
    </span>
    <span className="font-LatoBold font-bold italic mt-10 text-lg block esm:text-sm sm:mt-2">
      Projects
    </span>
    <span className="font-LatoRegular inline-block esm:text-sm">{projectName}</span>
  </button>
);

const Friend = ({ friend }) => {
  let { image, name, role, projectName } = friend;
  return (
    <div className="max-w-[18rem] w-1/5 md:w-2/5 m-4 md:m-1 relative">
      <div>
        <GatsbyImage
          alt={name}
          image={getImage(image)}
          className="h-80 w-full sm:h-64 esm:h-48"
          objectFit="cover"
          objectPosition={"center top"}
        />
      </div>
      <FriendHover name={name} role={role} projectName={projectName} />
    </div>
  );
};

const FriendsCompo = () => (
  <StaticQuery
    query={graphql`
      {
        allHomeJson {
          nodes {
            friends {
              image {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
              name
              role
              projectName
            }
          }
        }
      }
    `}
    render={({ allHomeJson: { nodes } }) => (
      <div id="friends" className="pt-10 ">
        <h1 className="text-3xl text-custom-teal-head md:text-center font-OswaldMedium pl-12 md:pl-0">
          Friends & Collaborators
        </h1>
        <div className="flex flex-row flex-wrap justify-center">
          {nodes[0].friends.map((friend, i) => (
            <Friend friend={friend} key={i} />
          ))}
        </div>
      </div>
    )}
  />
);

const GoodAt = () => (
  <div id="gootAt">
    <h1 className="text-3xl text-custom-teal-head md:text-center font-OswaldMedium pl-16 md:pl-0">
      What We Are Good At!
    </h1>
    <div className="flex flex-row flex-wrap pl-8 pr-8 m-auto">
      {Array.apply(null, Array(4)).map((val, i) => (
        <div
          className="flex flex-col items-center text-center w-1/4 md:w-1/2 p-2 text-custom-teal-body"
          key={i}>
          <div
            style={{ width: 80, height: 80, borderRadius: "50%" }}
            className="bg-custom-teal"></div>
          <p className="mt-4 font-MyriadProRegular">
            Our company constantly evolves to the booming technology,
          </p>
        </div>
      ))}
    </div>
  </div>
);

const People = () => (
  <div id="people" className="pt-10">
    <h1 className="text-3xl text-custom-teal-head md:text-center font-OswaldMedium pl-16 md:pl-0">
      The People
    </h1>
    <div className="flex flex-row flex-wrap justify-center">
      {Array.apply(null, Array(4)).map((val, i) => (
        <div
          className="bg-custom-teal max-w-[18rem] h-80 w-1/5 md:w-2/5 sm:h-64 esm:h-40 m-4 md:m-1"
          key={i}
        />
      ))}
    </div>
  </div>
);

const Main = () => {
  let containerSize = "max-w-[18rem] w-1/5 md:w-2/5 m-4 md:m-1";
  return (
    <div className="p-5 pt-10">
      <GoodAt />
      <People />
      <FriendsCompo />
    </div>
  );
};

export default Main;
