import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { AboutHeading, Contact, GoodAt, Mission } from "./Components";
import * as Style from "./about.module.css";

const About = () => {
  return (
    <div className="bg-[#fff9f0]">
      <AboutHeading />
      <div className={`${Style.main} ${Style.bgProp}`}>
        <div className="w-2/3 m-auto text-center pt-16 p-10 text-custom-teal-body md:w-full text-xl md:text-base">
          <p>
            Metal is versatile, ﬂuid and can be moulded into inﬁnite possibilities.
            For us, ceilings and facades provide a canvas to every space - they don't
            just add personality & ﬁnesse but also create a safe haven.
          </p>
          <p>
            We understand metal ceilings and facades in every form and push
            boundaries to help bring an artist's vision to life. We believe in
            creating bespoke ceilings and facades that are functional, backed by
            industry expertise and manufactured at a best-in-class facility.
          </p>
        </div>
        <div className="m-auto flex flex-row-reverse items-stretch p-5 pb-0 w-full  lg:w-5/6 md:flex-col pt-10">
          <div className="w-1/2 ml-32 md:w-4/5 md:m-auto mt-8 h-128 md:h-56 xl:h-108 2xl:h-96">
            <StaticImage
              src="../../assets/images/about/AI_Image.png"
              formats={["auto", "webp", "avif", "png"]}
              className="h-full w-full z-0 relative"
              alt="Site Logo"
            />
          </div>
          <div className="w-1/2 md:text-center md:w-full md:pt-10">
            <h1 className="herotitleOutline text-custom-teal-head md:text-3xl font-OswaldMedium">
              We Are the Iron Crew
            </h1>
            <div className="md:text-base text-sm text-custom-teal-body">
              <p>
                We accentuate and embellish ceilings and facades by uniquely shaping
                your space and interacting with the architecture volume. We represent
                a team of creative artists, fabricators and professional artisans
                designing distinctive forms for rooﬁng your spaces—our work deals
                with providing complete ceiling solutions with prioritized care and
                functional mechanisms. We bring the magic of the ﬁnest and most
                efﬁcient metal ceiling designs, elevating your spatial experience.{" "}
              </p>
              <p>
                SMIITH & MOULD is a bespoke architectural metalwork studio
                specialized in metal ceilings. Invoking innovation with skilled
                cutting-edge designs and proﬁcient technical acoustic expertise, we
                guarantee quality work and legal compliance from the spark of
                fabrication to clean and precise installation.
              </p>
              <p>
                We discovered a pressing need in the commercial construction market
                for ceiling and facade systems that{" "}
                <strong>
                  <i> "artisanally ﬁt the mould." </i>
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#deb35d] w-32 h-12 m-auto md:hidden" />
        <div className="flex items-stretch w-full m-auto lg:w-5/6">
          <div className="w-1/2 md:hidden h-full 2xl:h-128 xl:pl-10">
            <StaticImage
              src="../../assets/images/about/AI_Image2.tif"
              formats={["auto", "webp", "avif", "png"]}
              className="h-full w-full"
              alt="Site Logo"
            />
          </div>
          <Mission />
        </div>
      </div>
      <div className={`${Style.goodAt} ${Style.bgProp}`}>
        <GoodAt />
      </div>
      <div className={`${Style.contact} ${Style.bgProp}`}>
        <Contact />
      </div>
    </div>
  );
};

export default About;
