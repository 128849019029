import React from "react";
import { Link } from "gatsby";
import { contactUsButton } from "../about.module.css";

const AboutContact = () => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className="md:pl-8 md:pr-8 pt-10 pb-48 m-auto w-full pl-16 pr-16">
      <h1 className="text-3xl text-custom-teal-head font-OswaldMedium p-0 m-0 md:text-xl">
        Partner with us
      </h1>
      <div className="bg-custom-yellow h-1 mr-4 ml-4" style={{ flexGrow: 1 }} />
      <Link to="/contact-us">
        <button className={`text-custom-teal-head ${contactUsButton}`}>
          Contact Us
        </button>
      </Link>
    </div>
  );
};

export default AboutContact;
